import React, { useState } from "react";
import MainLayout from "../layouts/MainLayout";

import "./styles.careers.scss";

const jobs = [
  {
    title: "Marketing Associate",
    description:
      "We are looking for a marketing associate to help us with our marketing efforts.",
    responsibilities: [
      "Create marketing campaigns",
      "Analyze marketing data",
      "Create marketing reports",
    ],
    compensation: "Competitive",
    qualifications: [
      "Bachelor's degree in marketing",
      "2 years of marketing experience",
    ],
  },
];

const CareersPage = () => {
  const [openJob, setOpenJob] = useState(null);
  return (
    <MainLayout>
      <div className="careers">
        <h1>Careers</h1>
        <h2>Open Positions</h2>
        <div className="open-positions">
          {jobs.map((job, i) => (
            <div className="position" key={i}>
              <div
                className="summary"
                onClick={() => {
                  setOpenJob(openJob === i ? null : i);
                }}
              >
                <div>
                  <h4>{job.title}</h4>
                  <p>{job.description}</p>
                </div>
                <div>
                  {openJob === i ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      fill="#000000"
                      height="800px"
                      width="800px"
                      version="1.1"
                      id="Layer_1"
                      viewBox="0 0 330 330"
                      xmlSpace="preserve"
                    >
                      <path
                        id="XMLID_224_"
                        d="M325.606,229.393l-150.004-150C172.79,76.58,168.974,75,164.996,75c-3.979,0-7.794,1.581-10.607,4.394  l-149.996,150c-5.858,5.858-5.858,15.355,0,21.213c5.857,5.857,15.355,5.858,21.213,0l139.39-139.393l139.397,139.393  C307.322,253.536,311.161,255,315,255c3.839,0,7.678-1.464,10.607-4.394C331.464,244.748,331.464,235.251,325.606,229.393z"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      fill="#000000"
                      height="800px"
                      width="800px"
                      version="1.1"
                      id="Layer_1"
                      viewBox="0 0 330 330"
                      xmlSpace="preserve"
                    >
                      <path
                        id="XMLID_225_"
                        d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393  c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393  s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"
                      />
                    </svg>
                  )}
                </div>
              </div>

              <div className={openJob === i ? "details open" : "details"}>
                <h5>Responsibilities</h5>
                <ul>
                  {job.responsibilities.map((responsibility, i) => (
                    <li key={i}>{responsibility}</li>
                  ))}
                </ul>

                <h5>Compensation</h5>
                <p>{job.compensation}</p>

                <h5>Qualifications</h5>
                <ul>
                  {job.qualifications.map((qualification, i) => (
                    <li key={i}>{qualification}</li>
                  ))}
                </ul>

                <button
                  onClick={() => {
                    alert("Email your resume to careers@redsols.us");
                  }}
                >
                  Apply
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </MainLayout>
  );
};

export default CareersPage;
