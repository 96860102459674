import AppSvg from "../../assets/AppSvg";
import MainLayout from "../../layouts/MainLayout";

import "./styles.app.scss";

const AppPage = () => {
  const pricing = [
    {
      title: "Basic",
      price: 500,
      features: [
        "Starter Template",
        "Limited Support",
        "Single Platform (iOS or Android)",
        "Up to 5 Screens",
        "Basic UI/UX Design",
      ],
    },
    {
      title: "Standard",
      price: 5000,
      features: [
        "Standard Template",
        "Moderate Support",
        "Cross-Platform (iOS and Android)",
        "Up to 15 Screens",
        "Custom UI/UX Design",
        "Basic Backend Development",
      ],
    },
    {
      title: "Premium",
      price: 10000,
      features: [
        "Premium Template",
        "Priority Support",
        "Cross-Platform (iOS and Android)",
        "Unlimited Screens",
        "Custom UI/UX Design",
        "Advanced Backend Development",
        "API Integration",
        "Database Management",
      ],
    },
  ];

  const services = [
    {
      id: 1,
      service: "Mobile App Development",
      description:
        "Creating applications for mobile devices such as smartphones and tablets.",
      examples: [
        "iOS App Development",
        "Android App Development",
        "Cross-Platform Development",
      ],
    },
    {
      id: 2,
      service: "Custom App Development",
      description:
        "Tailoring apps according to the specific requirements of clients.",
      examples: [
        "Bespoke Features Development",
        "Custom UI/UX Design",
        "Client-Specific Functionalities",
      ],
    },
    {
      id: 3,
      service: "UI/UX Design",
      description:
        "Focusing on the user interface (UI) and user experience (UX) design of apps.",
      examples: ["Wireframing", "Prototyping", "User Research", "User Testing"],
    },
    {
      id: 4,
      service: "Backend Development",
      description:
        "Building the server-side logic and database management systems required to power mobile applications.",
      examples: [
        "API Development",
        "Database Management",
        "Server Infrastructure Setup",
      ],
    },
    {
      id: 5,
      service: "App Testing and Quality Assurance",
      description:
        "Conducting thorough testing of apps to identify and address any issues related to functionality, performance, security, and compatibility.",
      examples: [
        "Functional Testing",
        "Performance Testing",
        "Security Testing",
        "Compatibility Testing",
      ],
    },
  ];

  return (
    <MainLayout>
      <div className="app-page">
        <section className="services">
          <div className="left">
            <h1>App Development Service</h1>
            <AppSvg />
          </div>
          <div className="right">
            {services.map((service) => (
              <div key={service.id}>
                <h2>{service.service}</h2>
                <p>{service.description}</p>
              </div>
            ))}
          </div>
        </section>

        <div className="pricing">
          <h2>Our Pricing</h2>
          <div className="plans">
            {pricing.map((plan) => (
              <div key={plan.id} className="plan">
                <h3>{plan.title}</h3>
                <p>${plan.price}</p>
                <ul>
                  {plan.features.map((feature, index) => (
                    <li key={index}>{feature}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>

          <p>*Additional features can be added for $50 each.</p>
        </div>
      </div>
    </MainLayout>
  );
};

export default AppPage;
