import React from "react";
import MainLayout from "../layouts/MainLayout";
import Content from "../components/Content";

const HomePage = () => {
  return (
    <MainLayout>
      <Content />
    </MainLayout>
  );
};

export default HomePage;
