import "./App.scss";
import { Route, Routes } from "react-router-dom";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import HomePage from "./pages/HomePage";
import CareersPage from "./pages/CareersPage";
import WebPage from "./pages/WebPage/WebPage";
import AppPage from "./pages/AppPage/AppPage";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/careers" element={<CareersPage />} />

        <Route path="/web-development" element={<WebPage />} />
        <Route path="/app-development-service" element={<AppPage />} />
      </Routes>
    </div>
  );
}

export default App;
