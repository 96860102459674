import { Typography } from "@mui/material";
import React from "react";

import "./styles.scss";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <Typography variant="h4">Contact Us</Typography>
          <Typography>email: hello@redsols.us</Typography>
          {/* Add more contact details as needed */}
        </div>

        <div className="footer-section">
          <Typography variant="h4">Quick Links</Typography>
          <Typography>
            <a href="/#home">Home</a>
          </Typography>
          <Typography>
            <a href="/#services">Services</a>
          </Typography>
          <Typography>
            <a href="/#work">Our Work</a>
          </Typography>
          <Typography>
            <a href="/#contact">Contact</a>
          </Typography>
          {/* Add more links as needed */}
        </div>

        <div className="footer-section">
          <a
            className="social"
            style={{ fontSize: "2em" }}
            href="https://www.linkedin.com/company/redsols"
            rel="noopener noreferrer"
            target="_blank"
          >
            Connect with us
          </a>
        </div>
      </div>

      <div className="footer-bottom">
        <Typography variant="body2">
          &copy; 2024 Redsols. All rights reserved.
        </Typography>
      </div>
    </footer>
  );
};

export default Footer;
