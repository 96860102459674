import React from "react";
import "./styles.scss";

const Fire = () => {
  return (
    <div className="fire">
      <p>Touch me!</p>

      <div className="flames">
        <div className="flame"></div>
        <div className="flame"></div>
        <div className="flame"></div>
        <div className="flame"></div>
      </div>
      <div className="logs"></div>
    </div>
  );
};

export default Fire;
