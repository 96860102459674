import React from "react";
import "./styles.scss";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <header className="header">
      <div>
        <Link to="/">
          <h1>Redsols</h1>
        </Link>

        {/* <sub style={{ fontSize: 12 }}>
          <a
            style={{ color: "GrayText" }}
            target={"_parent"}
            href="https://yashwanthreddyveesarapu.web.app/"
          >
            {" "}
            By Yash
          </a>
        </sub> */}
      </div>
      <div className="menu">
        {/* <Link to="/products">Products</Link> */}

        <a target="_blank" rel="noreferrer" href="https://cloud.redsols.us/">
          Cloud
        </a>
        <a target="_blank" rel="noreferrer" href="https://blog.redsols.us/">
          blog
        </a>
      </div>
    </header>
  );
};

export default Header;
