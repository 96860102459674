import { motion } from "framer-motion";
import React, { useEffect } from "react";

import "./styles.scss";
import { Typography } from "@mui/material";

import huntImage from "./../../assets/images/hunt.webp";
import amplifyImage from "./../../assets/images/amplify.webp";
import mrRedashImage from "./../../assets/images/mrredash.webp";
import cloudImage from "./../../assets/images/cloud.webp";

let WORK_SAMPLES = [
  {
    title: "Amplify",
    url: "https://amplify.redsols.us/about",
    image: amplifyImage,
  },
  {
    title: "Hunt",
    url: "https://hunt.redsols.us/about",
    image: huntImage,
  },
  {
    title: "MrRedash",
    url: "https://www.mrredash.com/",
    image: mrRedashImage,
  },
  {
    title: "Cloud",
    url: "https://cloud.redsols.us/",
    image: cloudImage,
  },
];

WORK_SAMPLES = WORK_SAMPLES.concat(WORK_SAMPLES);

const Samples = () => {
  // detect if section is in view
  useEffect(() => {
    let inView = false;
    const section = document.getElementById("samples");
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("in-view");
          inView = true;
        } else {
          entry.target.classList.remove("in-view");
          inView = false;
        }
      });
    });

    const carousel = document.getElementById("carousel");

    let hovered = false;
    // on hover
    carousel.addEventListener("mouseover", () => {
      hovered = true;
    });

    // on leave
    carousel.addEventListener("mouseleave", () => {
      hovered = false;
    });

    // on click on any of the samples
    carousel.addEventListener("click", () => {
      console.log("clicked");
    });

    let counter = 1;
    setInterval(() => {
      if (hovered || !inView) return;
      carousel.style.transform = `translateX(${-counter * 1}px)`;
      counter = counter + 1;

      if (counter > carousel.scrollWidth / 2) {
        counter = 0;
        setTimeout(() => {}, 1000);
      }
    }, 10);

    observer.observe(section);
  }, []);
  return (
    <motion.section id="samples" className="">
      <Typography variant="h2" component="h2" gutterBottom>
        Our Work
      </Typography>
      <motion.div className="carousel" id="carousel">
        {WORK_SAMPLES.map((sample, index) => (
          <motion.div className="sub" key={index}>
            <div className="portfolio-image-container">
              <img alt={sample.title} src={sample.image} />
            </div>
            <a target={"_blank"} href={sample.url} rel="noreferrer">
              {sample.url}
            </a>
          </motion.div>
        ))}
      </motion.div>
    </motion.section>
  );
};

export default Samples;
