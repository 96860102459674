import { List, ListItemText, Typography } from "@mui/material";
import React, { useEffect } from "react";
import Fire from "./../Fire";
import "./styles.scss";

import { ReactComponent as Safe } from "./../../assets/safe.svg";
import { ReactComponent as Api } from "./../../assets/api.svg";
import { ReactComponent as Web } from "./../../assets/web.svg";

import { ReactComponent as ContactUs } from "./../../assets/contact.svg";
import ContactForm from "../Forms/ContactForm";
import Samples from "../Samples";

import { motion } from "framer-motion";
import Button from "../Button";

let tagline = "Elevate Your Digital Presence with Redsols.";

// const technologies = [
//   "React",
//   "React Native",
//   "Angular",
//   "Vue.js",
//   "Node.js",
//   "Express.js",
//   "Python",
//   "Java",
//   "Spring Boot",
//   "MySQL",
//   "MongoDB",
//   "PostgreSQL",
//   "SQLite",
//   "Redis",
//   "GraphQL",
// ];

const Content = () => {
  const [dark, setDark] = React.useState(true);

  useEffect(() => {
    if (dark) {
      document.documentElement.dataset.theme = "dark";
      document.documentElement.setAttribute("data-theme", "dark");
    } else {
      document.documentElement.dataset.theme = "light";
      document.documentElement.setAttribute("data-theme", "light");
    }
  }, [dark]);

  return (
    <div className="content">
      <div className="content1">
        <div className="part" id="home">
          <motion.h1
            initial={{ opacity: 0.1 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className="title"
          >
            {tagline.split("").map((char, index) => {
              return (
                <motion.span
                  key={char + "-" + index}
                  initial={{ opacity: 0.1 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: index * 0.05 }}
                >
                  {char}
                </motion.span>
              );
            })}
          </motion.h1>

          <div className="det">
            <motion.h2
              initial={{ opacity: 0.1 }}
              animate={{ opacity: 1, z: 10 }}
              transition={{ duration: 1, delay: 2 }}
              viewport={{ once: true }}
            >
              Wanna show your presence on internet? scale up? better ui? <br />
              We are here for you.
            </motion.h2>
          </div>
          <Button
            onClick={() => {
              document.getElementById("four").scrollIntoView({
                behavior: "smooth",
              });
            }}
          >
            Our Work
          </Button>
        </div>
        <div id="two" className="part">
          <motion.div
            className="sub-part"
            initial={{ opacity: 0, scale: 0.9 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
          >
            <Typography variant="h2">Why Choose Redsols?</Typography>
            <List>
              <ListItemText>Customized Code for Performance</ListItemText>
              <ListItemText>Responsive Design</ListItemText>
              <ListItemText>Security First</ListItemText>
            </List>
          </motion.div>

          <motion.div className="inspire">
            <motion.div
              initial={{ x: "100%", opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 1, delay: 0.5 }}
              viewport={{ once: true }}
            >
              <Api />
            </motion.div>
            <motion.div>
              <Web />
            </motion.div>
            <motion.div
              initial={{ x: "-100%", opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 1, delay: 0.5 }}
              viewport={{ once: true }}
            >
              <Safe />
            </motion.div>
          </motion.div>
        </div>

        <div className="part" id="services">
          <motion.div
            className="sub-part"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 0.9, scale: 1 }}
            transition={{ duration: 1 }}
          >
            <Typography variant="h2">What we do?</Typography>
            <List style={{ flexDirection: "column" }}>
              <ListItemText>Web Designing</ListItemText>
              <ListItemText>Web Development</ListItemText>
              <ListItemText>Mobile App Development</ListItemText>
              <ListItemText>Custom Software Development </ListItemText>
              <ListItemText>Search Engine Optimization</ListItemText>
            </List>
          </motion.div>
        </div>

        <div className="part" id="four">
          <Samples />
        </div>

        <div className="part" id="five">
          <Typography variant="h2">Latest Updates</Typography>
          {/* <Typography variant="h3">Our Blog</Typography> */}
          <div>
            <iframe loading="lazy" title="Blog" src="https://blog.redsols.us" />
            <br />
            <a href="https://blog.redsols.us" rel="noreferrer" target="_blank">
              https://blog.redsols.us/
            </a>

            <br />
          </div>
        </div>

        <div className="part" id="contact">
          <div className="contact-content">
            <div className="c-c-left">
              <Typography variant="h2">Get In Touch With Us</Typography>
              <div className="svg">
                <ContactUs />
              </div>
            </div>

            <div className="c-c-right">
              <ContactForm />
            </div>
          </div>
        </div>

        <div
          aria-label="Toggle Dark Mode"
          id="fire"
          onClick={() => {
            setDark(!dark);
          }}
          className="fire"
        >
          <Fire />
        </div>
      </div>
    </div>
  );
};

export default Content;
