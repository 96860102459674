import WebSvg from "../../assets/WebSvg";
import MainLayout from "../../layouts/MainLayout";

import "./styles.web.scss";

const WebPage = () => {
  const services = [
    {
      id: 1,
      title: "Custom Web Development",
      description:
        "We provide tailor-made web development solutions to meet your unique business requirements. Our experienced team will work closely with you to create a custom web application that aligns perfectly with your goals.",
    },
    {
      id: 2,
      title: "E-commerce Development",
      description:
        "Our e-commerce development services help businesses establish a robust online presence and drive sales. From building secure payment gateways to optimizing product listings, we've got you covered.",
    },
    {
      id: 3,
      title: "Responsive Web Design",
      description:
        "In today's mobile-driven world, it's essential to have a website that looks great and functions seamlessly across all devices. We specialize in responsive web design to ensure your site looks amazing on desktops, tablets, and smartphones.",
    },
  ];

  const pricing = [
    {
      id: 1,
      title: "Basic",
      price: 500,
      features: ["5 Pages", "Responsive Design", "1 Revision"],
    },
    {
      id: 2,
      title: "Standard",
      price: 1000,
      features: ["10 Pages", "Responsive Design", "2 Revisions"],
    },
    {
      id: 3,
      title: "Premium",
      price: 2000,
      features: ["15 Pages", "Responsive Design", "3 Revisions"],
    },
  ];

  return (
    <MainLayout>
      <div className="web">
        <section cla>
          <div className="left">
            <h1>Web Development Service</h1>
            <WebSvg />
          </div>
          <div className="right">
            {services.map((service) => (
              <div key={service.id}>
                <h2>{service.title}</h2>
                <p>{service.description}</p>
              </div>
            ))}
          </div>
        </section>

        <div className="pricing">
          <h3>Our Pricing</h3>
          <div className="plans">
            {pricing.map((plan) => (
              <div key={plan.id} className="plan">
                <h3>{plan.title}</h3>
                <p>${plan.price}</p>
                <ul>
                  {plan.features.map((feature, index) => (
                    <li key={index}>{feature}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>

          <p>*Additional pages can be added for $100 each.</p>
        </div>
      </div>
    </MainLayout>
  );
};

export default WebPage;
