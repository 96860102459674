import React from "react";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { analytics, app } from "./firebase/config";

const root = createRoot(document.getElementById("root"));

// Initialize Firebase

function initializeApp() {
  // Initialize Firebase
  // do not store in variables

  return { app, analytics };
}

initializeApp();

root.render(
  <BrowserRouter>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </BrowserRouter>
);
