import React from "react";
import MainLayout from "../layouts/MainLayout";

const PrivacyPolicy = () => {
  return (
    <MainLayout>
      <h1>Privacy Policy</h1>

      <p>Last Updated: June 16, 2024</p>

      <h2>1. Introduction</h2>

      <p>
        Welcome to Redsols ("us," "we," or "our"). This Privacy Policy outlines
        how we collect, use, share, and protect your personal information on our
        website
        <a href="https://www.redsols.us/">https://www.redsols.us/</a> (the
        "Site").
      </p>

      <h2>2. Information We Collect</h2>

      <h3>Personal Information</h3>
      <p>
        We may collect personal information, including but not limited to names,
        email addresses, and phone numbers when voluntarily provided by users
        through our contact forms or other means.
      </p>

      <h3>Log Data</h3>
      <p>
        We gather information that your browser sends whenever you visit our
        Site ("Log Data"). This Log Data may include information such as your
        computer's IP address, browser type, pages visited, and timestamps.
      </p>

      <h3>Cookies</h3>
      <p>
        We use cookies to enhance your experience on our Site. Cookies are small
        files stored on your device that improve website functionality. You can
        disable cookies in your browser settings but may limit certain features.
      </p>

      <h2>3. How We Use Your Information</h2>

      <p>We use collected information for the following purposes:</p>
      <ul>
        <li>To provide and maintain our website.</li>
        <li>
          To communicate with you, respond to inquiries, and send updates.
        </li>
        <li>To analyze and improve our services and user experience.</li>
      </ul>

      <h2>4. Information Sharing</h2>

      <p>
        We do not sell, trade, or rent your personal information to third
        parties. We may share information with trusted service providers who
        assist in operating our website or servicing you, subject to
        confidentiality obligations.
      </p>

      <h2>5. Data Storage</h2>

      <p>
        We do not store any email data except for the purposes related to the
        provided services.
      </p>

      <h2>6. Security</h2>

      <p>
        We prioritize the security of your personal information but note that no
        method of transmission over the internet or electronic storage is
        entirely secure. We implement reasonable safeguards to protect your
        data.
      </p>

      <h2>7. Third-Party Links</h2>

      <p>
        Our website may contain links to third-party websites. We are not
        responsible for their privacy practices and encourage you to review
        their privacy policies.
      </p>

      <h2>8. Changes to This Privacy Policy</h2>

      <p>
        We reserve the right to update our Privacy Policy. Updates will be
        posted on this page, and the "Last Updated" date will reflect changes.
        We recommend reviewing this Privacy Policy periodically.
      </p>

      <h2>9. Contact Us</h2>

      <p>
        If you have questions or concerns about our Privacy Policy, please
        contact us at <a href="mailto:hello@redsols.us">hello@redsols.us</a>.
      </p>

      <p>
        By using our website, you agree to the terms of this Privacy Policy.
      </p>
    </MainLayout>
  );
};

export default PrivacyPolicy;
