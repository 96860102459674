// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA2n_cmeRkj9w_QJ7SWOy_FnBwV_j9xa0o",
  authDomain: "redsols.firebaseapp.com",
  projectId: "redsols",
  storageBucket: "redsols.appspot.com",
  messagingSenderId: "103950793791",
  appId: "1:103950793791:web:acdb55698a6a04fbbbcc8a",
  measurementId: "G-MNRCYPLVK3",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
